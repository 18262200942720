import * as React from 'react'

import { Container, Row, Col } from 'react-bootstrap'

import ContentItem from 'content/ContentItem'

import PageSection from 'components/shared/PageSection/PageSection'

import 'components/front/sections/WhoAreWe/WhoAreWe.scss'

const WhoAreWeFigureInlineSvg = require('assets/images/who-we-are-bg.inline.svg')

export const WhoAreWe = (
  { content: { html, frontmatter: { title, name } } }: { content: ContentItem },
) => (
  <PageSection id={name} className="WhoAreWe d-md-flex">
    <Container fluid>
      <Row noGutters>
        <Col xs={12} sm={6} lg={8} xl={6}>
          <article
            className="
              WhoAreWe__container
              d-md-flex
              pl-4 pl-sm-5
              pr-4 pr-sm-5
              u-full-height
            "
          >
            <div className="pt-4 pt-sm-0">
              <h2 className="WhoAreWe__title mb-3">{title}</h2>
              <div
                className="WhoAreWe__body"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </article>
        </Col>
        <Col sm={6} lg={4} xl={6}>
          <div className="WhoAreWe__figure d-none d-sm-flex u-full-height">
            <img
              className="WhoAreWe__figureImage"
              src={WhoAreWeFigureInlineSvg}
              alt=""
            />
          </div>
        </Col>
      </Row>
    </Container>
  </PageSection>
)

export default WhoAreWe
