import * as React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

type AddressMapProps = {
  apiKey: string
  defaultCenter : {
    lat: number
    lng: number
  }
}

const propsRef: AddressMapProps = null

/**
 * Component incapsulates a map with the company address
 */
class AddressMap extends React.Component<AddressMapProps> {
  private readonly apiKey: string
  private readonly defaultCenter: typeof propsRef.defaultCenter

  constructor(props: AddressMapProps) {
    super(props)

    const { apiKey, defaultCenter  } = props
    this.apiKey = apiKey
    this.defaultCenter = defaultCenter
  }

  get googleMapUrl() {
    return `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}`
  }

  withHOCs(children: any): any {
    return withScriptjs(withGoogleMap(() => children))
  }

  renderMap() {
    return (
      <GoogleMap
        defaultZoom={17}
        defaultCenter={this.defaultCenter}
      >
        <Marker position={this.defaultCenter}/>
      </GoogleMap>
    )
  }

  render() {
    const Map = this.withHOCs(this.renderMap())

    return (
      <Map
        googleMapURL={this.googleMapUrl}
        loadingElement={<div style={{ height: '100%' }}/>}
        containerElement={<div
          className="AddressMap__mapOuter d-none d-md-block"
          style={{
            position: 'relative',
            textAlign: 'right',
            height: '100%',
            width: '100%',
          }}
        />}
        mapElement={<div
          className="AddressMap__gmapCanvas"
          style={{
            overflow: 'hidden',
            background: 'none !important',
            height: '100%',
            width: '100%',
          }}
        />}
      />
    )
  }
}

export default AddressMap
