import * as React from 'react'
import * as Yup from 'yup'

import ContentItem from 'content/ContentItem'
import AddressMap from 'components/front/AddressMap/AddressMap'

const MSG_SHORT = 'Too Short!'
const MSG_LONG = 'Too Long!'
const MSG_REQUIRED = 'Required!'
const MSG_INVALID_EMAIL = 'Invalid email'

export type ContactProps = {
  content: ContentItem
  contactForm: {
    action: string
  }
  formValues?: FormSchema
  noSubmit?: boolean
  displayErrors?: boolean
  errorsMap?: {readonly [key in keyof FormSchema ]: string[]}
} & React.ComponentProps<typeof AddressMap>

export type ArrayMap = {
  [key: string]: string[] | null
}

/**
 * Defines validation schema for the contact form.
 * Names of fields should not be changed (expected by webmail service).
 */
export const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, MSG_SHORT)
    .max(100, MSG_LONG)
    .required(MSG_REQUIRED),
  _replyTo: Yup.string()
    .email(MSG_INVALID_EMAIL)
    .required(MSG_REQUIRED),
  company: Yup.string()
    .max(100, MSG_LONG)
    .nullable()
    .notRequired(),
  message: Yup.string()
    .max(500, MSG_LONG)
    .nullable()
    .notRequired(),
})

export type FormSchema = Yup.InferType<typeof SignupSchema>
