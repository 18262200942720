import * as React from 'react'
// tslint:disable:line no-duplicate-imports
import { useState } from 'react'

const classNames = require('classnames')

import { Container, Row, Col } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group'

import ContentItem from 'content/ContentItem'

import PageSection from 'components/shared/PageSection/PageSection'
import DnaMenu from 'components/front/DnaMenu/DnaMenu'
import DnaMenuContext from 'components/front/DnaMenu/DnaMenuContext'

import 'components/front/sections/OurDna/OurDna.scss'

export type OurDnaProps = {
  frontmatter: {
    title: string
    name: string
  };
}

export const OurDna = ({
  items = {},
  content: {
    frontmatter: { title, name },
  } = {
    frontmatter: { name: null, title: null },
  },
}: {
  content: OurDnaProps,
  items: {
    [key: string]: ContentItem,
  },
}) => {
  const [state, setState] = useState({
    activeKey: 'research',
  })

  const updateActiveKey = (key: string) => setState({ activeKey: key })

  /**
   * Render all figures but display only active one
   */
  const renderSubsectionBody = (key: string) => {
    const subsection = items[key]
    const sectionClass = classNames(
      'OurDna__subSection text-left text-md-center mb-4 mb-md-0', {
        'OurDna__subSection--state-enter-done': key === state.activeKey,
      },
    )

    return (
      <CSSTransition
        key={key}
        in={key === state.activeKey}
        timeout={0}
        classNames="OurDna__subSection--state"
      >
        <section className={sectionClass}>
          <header className="mb-3">
            <h3 className="OurDna__subSectionTitle text-left text-md-center">
              {subsection.frontmatter.title}
            </h3>
          </header>
          <article
            className="OurDna__container text-left text-md-center"
            dangerouslySetInnerHTML={{ __html: subsection.html }}
          />
        </section>
      </CSSTransition>
    )
  }

  return (
    <PageSection id={name} className="OurDna d-md-flex">
      <Container fluid>
        <Row noGutters>
          <Col>
            <h2
              className="
                OurDna__title text-center
                mb-5
                mb-lg-2 mt-lg-4
              "
            >
              {title}
            </h2>

            <DnaMenuContext.Provider value={{ ...state, updateActiveKey }}>
              <DnaMenu items={items}/>
            </DnaMenuContext.Provider>
            <div className="OurDna__subSectionWrapper">
              {Object.keys(items).map(key => renderSubsectionBody(key))}
            </div>
          </Col>
        </Row>
      </Container>
      <div className="OurDna__flyingBg"/>
    </PageSection>
  )
}

export default OurDna
