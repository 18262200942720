import * as React from 'react'

import 'components/front/sections/Contact/ErrorMessage/ErrorMessage.scss'

class ErrorMessage extends React.Component<{ message: string[] }> {
  render(): React.ReactNode {
    const { message } = this.props

    return message
      ? (<ul className="ErrorMessage__list m-0 p-0 mt-2">{
        message.map(text => (
          <li className="ErrorMessage__listItem" key={text}>
            {text}
          </li>
        ))
      }</ul>)
      : null
  }
}

export default ErrorMessage
