import * as React from 'react'
import { LayoutProps } from 'components/Layout'

import mapContent from 'shared/util/mapContent'
import getWebPageCombined from 'shared/util/schema/getWebPageCombined'

import PageHeader from 'components/shared/PageHeader/PageHeader'
import PageFooter from 'components/shared/PageFooter/PageFooter'

import MainSection from 'components/front/sections/MainSection/MainSection'
import WhoAreWe from 'components/front/sections/WhoAreWe/WhoAreWe'
import OurDna from 'components/front/sections/OurDna/OurDna'
import Team from 'components/front/sections/Team/Team'
import Blog from 'components/front/sections/Blog/Blog'
import Contact from 'components/front/sections/Contact/Contact'
import JsonLd from 'components/scripts/JsonLd/JsonLd'

export type IndexPageProps = LayoutProps

const IndexPage = (props: IndexPageProps) => {
  const content = mapContent(props.data.content.edges)
  const contentDnaItems = mapContent(props.data.contentDna.edges)
  const {
    menuItems,
    footer,
    contactForm,
    schema,
    googleMap: { apiKey, defaultCenter },
  } = props.websiteConfig
  const blogPostsWidget = props.data.blogPostsWidget.edges

  return (
    <React.Fragment>
      <JsonLd>{getWebPageCombined(schema)}</JsonLd>
      <PageHeader
        pathname={props.location.pathname}
        items={menuItems}
        texts={{
          ...props.data.site.siteMetadata,
        }}
      />
      <MainSection content={content.main}/>
      <WhoAreWe content={content['who-are-we']}/>
      <OurDna
        content={content['our-dna']}
        items={contentDnaItems}
      />
      <Team
        content={content.team}
        teammateDefs={props.data.teammateDefs.edges}
        teammateImages={props.data.teammateImages.edges}
      />
      <Blog
        content={content.blog}
        postsPerPage={3}
        blogPosts={blogPostsWidget}
      />
      <Contact
        {...{
          apiKey,
          contactForm,
          defaultCenter,
          formValues: {
            name: '',
            _replyTo: '',
            company: '',
            message: '',
          },
        }}
        content={content.contact}
      />
      <PageFooter {...footer}/>
    </React.Fragment>
  )
}

export default IndexPage
