import * as React from 'react'

import { Container } from 'react-bootstrap'
import Img from 'gatsby-image'

import ContentItem from 'content/ContentItem'

import PageSection from 'components/shared/PageSection/PageSection'

import 'components/front/sections/Blog/Blog.scss'

type BlogProps = {
  content: ContentItem
  blogPosts: BlogPostTeaser[]
  postsPerPage: number
}

export type BlogPostTeaser = {
  node: {
    frontmatter: {
      excerpt: string
      image: { childImageSharp: ChildImageSharp }
      title: string
      slug: string
    }
  }
}

export class Blog extends React.Component<BlogProps> {
  /**
   * Render a blog post card
   */
  renderBlogPost = (
    post: BlogPostTeaser,
    index: number,
  ): React.ReactNode => {
    const { node: { frontmatter: { title, excerpt, image, slug } } } = post

    return (
      <div className="Blog__post d-flex flex-column text-left mb-4" key={index}>
        <Img
          fluid={image.childImageSharp.fluid}
          className="Blog__postImage d-block"
          alt={title}
        />
        <header className="Blog__postHeader d-flex flex-column flex-grow-1">
          <h3 className="Blog__postTitle mb-3">{title}</h3>
          <div className="Blog__postTeaser mb-3">{excerpt}</div>
          <a
            className="Blog__postUrl d-block font-weight-bold text-right text-uppercase"
            href={slug}
          >
            Read More
          </a>
        </header>
      </div>)
  }

  /**
   * Render the component (section)
   */
  render(): React.ReactNode {
    const {
      content: {
        html, frontmatter: { title, name },
      },
      blogPosts,
      postsPerPage,
    } = this.props

    const posts = blogPosts.length > postsPerPage
      ? blogPosts.slice(0, postsPerPage)
      : blogPosts

    return (
      <PageSection id={name} className="Blog d-md-flex">
        <Container>
          <article
            className="
              Blog__container
              d-flex flex-column
              align-items-center
              justify-content-center
              u-full-height
              text-center
            "
          >
            <header className="mb-4">
              <h2 className="Blog__title mb-3">{title}</h2>
              <div
                className="Blog__body"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </header>
            <section
              className="
                Blog__feed
                d-flex flex-wrap flex-row
                justify-content-center justify-content-md-between
                u-full-width
              "
            >
              {posts.map(this.renderBlogPost)}
            </section>
          </article>
        </Container>
      </PageSection>
    )
  }
}

export default Blog
