import * as React from 'react'
import { graphql } from 'gatsby'

import { withLayout } from 'components/Layout'

import IndexPage, {
  IndexPageProps
} from 'components/pages/IndexPage/IndexPage'

/**
 * Mediator between a real page component and Gatsby conventions:
 * decoupled for the tests sake
 * (currently graphql is not available in Storybook)
 */
const index = (props: IndexPageProps) => <IndexPage {...props} />

export const query = graphql`
query {
  # Site metadata
  site {
    siteMetadata {
      title
      description
      logoTitle
      logoSecondaryTitle
      logoImageAlt
    }
  }
  # Root Sections
  content: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "^/content/((?!OurDna_).)*$/"}}
  ) {
    edges {
      node {
        frontmatter {
          title
          subTitle
          name
        }
        html
      }
    }
  }
  # DNA Subsections
  contentDna: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/OurDna_/"}}
    sort: {fields: frontmatter___weight}
  ) {
    edges {
      node {
        frontmatter {
          title
          name
          weight
        }
        html
      }
    }
  }
  # Team members images for the gallery
  teammateImages: allFile(
    filter: {relativePath : {regex: "/team-gallery/"}}
  ) {
    edges {
      node {
        fileName: base
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
  # Team members definitions
  teammateDefs: allTeammatesJson(
    sort: {fields: weight}
  ) {
    edges {
      node {
        fileName
        title
        link
        subTitle
        displayTitle
        displaySubTitle
      }
    }
  }
  # Blog widgets
  blogPostsWidget: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "^/content/blog/.+$/"}},
    sort: {fields: frontmatter___date, order: DESC}, limit: 3
  ) {
    edges {
      node {
        frontmatter {
          slug
          title
          excerpt
          image {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
}
`

export default withLayout(index)
