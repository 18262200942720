import * as React from 'react'
// tslint:disable:line import-name
import Img from 'gatsby-image'

import { Container } from 'react-bootstrap'
import ContentItem from 'content/ContentItem'

import PageSection from 'components/shared/PageSection/PageSection'

import 'components/front/sections/Team/Team.scss'

const classNames = require('classnames')

export type GalleryItem = {
  fileName: string
  title: string
  link?: string
  subTitle?: string
  displayTitle?: boolean
  displaySubTitle?: boolean
  weight?: number
}

export type GalleryImage = {
  node: {
    fileName: string
    childImageSharp: ChildImageSharp
  }
}

type TeamProps = {
  content: ContentItem
  teammateDefs: DefWrapped[]
  teammateImages: GalleryImage[]
}

type ImageDefMap = {
  [key: string]: GalleryImage
}

type DefWrapped = {
  node: GalleryItem
}

export class Team extends React.Component<TeamProps> {
  private readonly imageDefsMap: ImageDefMap

  constructor(props: TeamProps) {
    super(props)

    this.imageDefsMap = Team.mapImageDefsToHashMap(this.props.teammateImages)
  }

  /**
   * Convert a flat array of teammate image defs to key-indexed map
   * for the better performance and easying of querying
   */
  static mapImageDefsToHashMap(images: GalleryImage[]): ImageDefMap {
    return images.reduce((acc: ImageDefMap, { node }: GalleryImage): ImageDefMap => {
      acc[node.fileName] = { node }

      return acc
    }, {})
  }

  /**
   * Render a gallery card with pic and texts if needed
   * Renders image with a srcset and any possible optimisations
   */
  renderGalleryItem = (
    itemDef: DefWrapped,
    index: number,
  ): React.ReactNode => {
    const { node: item } = itemDef
    const imageDef: GalleryImage = this.imageDefsMap[item.fileName]

    if (!imageDef) {
      throw new Error(
        `Image definition of <GalleryImage> type is not found for fileName: '${item.fileName}'`,
      )
    }

    const { node: { childImageSharp } } = imageDef

    const Component = item.link !== null  ? 'a' : 'div'
    const componentProps = {
      href: item.link !== null ? item.link : null,
    }
    const itemClassname = classNames(
      `Team__galleryItem Team__galleryItem--no${index} d-flex justify-content-around`, {
        'Team__galleryItem--displayTitle': item.displayTitle,
        'Team__galleryItem--displaySubTitlexx': item.displaySubTitle,
      })

    const titles = item.displayTitle || item.subTitle ? (
      <header className="Team__galleryItem__header">
        {item.displayTitle && (
          <h4 className="Team__galleryItemTitle mb-5">{item.title}</h4>
        )}

        {item.displaySubTitle && (
          <h5 className="Team__galleryItemSubTitle">{item.subTitle}</h5>
        )}
      </header>
    ) : null

    return (
      <Component className={itemClassname} key={`${item.title}${index}`} {...componentProps}>
        <Img
          key={index}
          fluid={childImageSharp.fluid}
          className="Team__galleryItem__image"
          alt={item.title}
          title={item.title}
        />
        {titles}
      </Component>)
  }

  /**
   * Render the component (section)
   */
  render(): React.ReactNode {
    const {
      content: {
        html, frontmatter: { title, name },
      },
      teammateDefs,
    } = this.props

    return (
      <PageSection id={name} className="Team d-md-flex">
        <Container>
          <article
            className="
              Team__container
              d-flex flex-column
              align-items-center
              justify-content-center
              u-full-height
              text-center
            "
          >
            <header className="mb-4">
              <h2 className="Team__title mb-3 mt-lg-4">{title}</h2>
              <div
                className="Team__body"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </header>
            <section
              className="
                Team__gallery
                d-flex flex-wrap
                justify-content-around
                u-full-width
              "
            >
              {teammateDefs.map(this.renderGalleryItem)}
            </section>
          </article>
        </Container>
      </PageSection>
    )
  }
}

export default Team
