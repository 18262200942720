import * as React from 'react'
// tslint:disable:line no-duplicate-imports
import { ReactElement } from 'react'
import { CSSTransition } from 'react-transition-group'

const classNames = require('classnames')

import ContentItem from 'content/ContentItem'
import DnaMenuContext from 'components/front/DnaMenu/DnaMenuContext'

import 'components/front/DnaMenu/DnaMenu.scss'

const DnaFigureSvg = require('assets/images/dna-figure.svg')

type DnaMenuProps = {
  items: { [key: string]: ContentItem }
}

/**
 * Component renders radial menu with various item nav length
 * and custom angles.
 *
 * Each item gets a class, modified with menu item name =>
 * could be configured in SCSS stylesheet.
 *
 * Menu connects to above component via DnaMenuContext
 * and shares an current menu item index.
 *
 * @see ./README.md
 */
class DnaMenu extends React.Component<DnaMenuProps> {
  static contextType = DnaMenuContext

  static defaultProps = Object.freeze({
    items: {},
  })

  private readonly navItemNames: string[]
  private readonly navItemsLength: number

  context!: React.ContextType<typeof DnaMenuContext>

  constructor(props: DnaMenuProps) {
    super(props)

    this.navItemNames = Object.keys(this.props.items)
    this.navItemsLength = this.navItemNames.length
  }

  /**
   * Render menu item
   */
  renderItem(key: string, index: number): ReactElement<any> {
    const {
      frontmatter: { title },
    } = this.props.items[key]

    const itemClassName = `
      OurDna__menuItem
      OurDna__menuItem--${key}
    `
    const wrapperClassName = classNames(
      `OurDna__menuItemWrapper OurDna__menuItemWrapper--${index}`, {
        'OurDna__menuItemWrapper--active': key === this.context.activeKey,
      },
    )

    return (
      <li className={wrapperClassName} key={key}>
        <button className={itemClassName} onClick={event => this.hover(key)}>
          {title}
        </button>
      </li>
    )
  }

  /**
   * Broadcast new active index to the context
   */
  hover(key: string) {
    this.context.updateActiveKey(key)
  }

  /**
   * Render all figures but display only active one
   */
  renderItemFigure(key: string) {
    const className = classNames(
      'OurDna__menuItemFigure',
      `OurDna__menuItemFigure--${key}`, {
        'OurDna__menuItemFigure--state-enter-done': key === this.context.activeKey,
      },
    )

    return (
      <CSSTransition
        key={key}
        in={key === this.context.activeKey}
        timeout={0}
        classNames="OurDna__menuItemFigure--state"
      >
        <div className={className}/>
      </CSSTransition>
    )
  }

  /**
   *  Render full menu nav structure
   */
  render() {
    const menuClassName = `
      OurDna__menu
      OurDna__menu--${this.navItemsLength}-items
    `

    return (
      <nav className="OurDna__navWrapper mb-4 d-none d-md-block">
        <div className="OurDna__menuContainer">
          <ul className={menuClassName}>
            {this.navItemNames.map((key, index) => this.renderItem(key, index))}
          </ul>
          <div className="OurDna__figure">
            <img src={DnaFigureSvg} alt=""/>
            {this.navItemNames.map(key => this.renderItemFigure(key))}
          </div>
        </div>
      </nav>
    )
  }
}

export default DnaMenu
