import * as React from 'react'

import { Container, Row, Col } from 'react-bootstrap'

import ContentItem from 'content/ContentItem'

import PageSection from 'components/shared/PageSection/PageSection'

import 'components/front/sections/MainSection/MainSection.scss'

const mainSectionFigureInlineSvg = require('assets/images/main-section-figure.inline.svg')

export const MainSection = (
  { content: { html, frontmatter: { title, name } } }: { content: ContentItem },
) => (
  <PageSection id={name} className="MainSection">
    <Container fluid>
      <article className="MainSection__container">
        <header className="MainSection__row MainSection__row--header">
          <Row noGutters>
            <Col>
              <h2 className="MainSection__title pr-0">{title}</h2>
            </Col>
          </Row>
        </header>
        <div className="MainSection__row">
          <Row noGutters>
            <Col>
              <div
                className="MainSection__body"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <div className="MainSection__figure">
                <img
                  className="MainSection__figureImage"
                  src={mainSectionFigureInlineSvg}
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </div>
      </article>
    </Container>
  </PageSection>
)

export default MainSection
