import * as React from 'react'
import { ValidationError } from 'yup'

import { Container, Row, Col } from 'react-bootstrap'

import PageSection from 'components/shared/PageSection/PageSection'
import AddressMap from 'components/front/AddressMap/AddressMap'

import 'components/front/sections/Contact/Contact.scss'
import { FormSchema, ContactProps, SignupSchema, ArrayMap } from './declarations'
import ErrorMessage from 'components/front/sections/Contact/ErrorMessage/ErrorMessage'

export class Contact extends React.Component<ContactProps> {
  static defaultProps: {
    noSubmit: false
  }

  state: {
    valid: boolean,
    form: FormSchema,
    errors: ValidationError,
  } = {
    valid: undefined,
    form: { ...this.props.formValues },
    errors: null,
  }

  private readonly formRef: React.RefObject<any>

  constructor(props: ContactProps) {
    super(props)

    this.formRef = React.createRef()
  }

  /**
   * Normalize error messages: if there is something else
   * except of `required` type error, keep only `required` one.
   */
  normalizeErrors(errorsMap: ArrayMap): ArrayMap {
    return Object.keys(errorsMap).reduce((acc, path) => {
      if (errorsMap[path].length > 1) {
        const filtered = errorsMap[path].filter(
          message => message === 'Required!',
        )

        if (filtered.length) {
          return {
            ...acc,
            [path]: filtered,
          }
        }
      }

      return {
        ...acc,
        [path]: errorsMap[path],
      }
    }, {})
  }

  /**
   * Convert ValidationError structure into hashmap {fieldName => errors[]}
   */
  processErrorSet(errorsSet: ValidationError): ArrayMap {
    const errorsMap: ArrayMap = {}

    if (!errorsSet || !errorsSet.inner) {
      return errorsMap
    }

    errorsSet.inner.forEach(({ path, message }) => {
      if (!errorsMap[path]) {
        errorsMap[path] = []
      }
      errorsMap[path] = [...errorsMap[path], message]
    })

    return this.normalizeErrors(errorsMap)
  }

  handleSubmit = async (event: React.FormEvent) => {
    event.persist()

    if (!this.state.valid) {
      event.preventDefault()
    }

    try {
      const valid = await SignupSchema.validate(this.state.form, { abortEarly: false })

      this.setState({ valid }, () => {
        if (!this.props.noSubmit) {
          const { contactForm: { action } } = this.props

          this.formRef.current.action = action
          this.formRef.current.submit()
        }
      })
    } catch (errors) {
      event.preventDefault()
      this.setState({ errors, valid: false })
    }
  }

  handleChangeFor = (
    name: string,
  ) => (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    this.setState({
      form: { ...this.state.form, [name]: event.currentTarget.value },
    })
  }

  renderForm() {
    const {
      noSubmit,
      displayErrors,
      errorsMap: errorsMapPredefined,
    } = this.props

    const errorsMap = displayErrors
      ? errorsMapPredefined
      : this.processErrorSet(this.state.errors)
    const { form } = this.state

    const FormComponent = noSubmit ? 'div' : 'form'
    const formProps = noSubmit
      ? { 'data-form-stub': true }
      : {
        method: 'POST',
        onSubmit: this.handleSubmit,
        ref: this.formRef,
      }
    const submitButtonHandler = noSubmit ? this.handleSubmit : null

    return (
      <FormComponent {...formProps}>
        <div
          className="
            Contact__FormControlGroup Contact__FormControlGroup--labeled
          "
        >
          <label
            htmlFor="contactUs--name"
            className="form-label Contact__FormControLabel"
          >
            Name
          </label>
          <input
            name="name"
            id="contactUs--name"
            className="form-control Contact__FormControl Contact__FormControl--required"
            onChange={this.handleChangeFor('name')}
            value={form.name}
          />
        </div>
        <ErrorMessage message={errorsMap.name}/>
        <div
          className="
            Contact__FormControlGroup Contact__FormControlGroup--labeled
            mt-4
          "
        >
          <label
            htmlFor="contactUs--email"
            className="form-label Contact__FormControLabel"
          >
            Email
          </label>
          <input
            name="_replyTo"
            id="contactUs--email"
            type="email"
            className="form-control Contact__FormControl Contact__FormControl--required"
            onChange={this.handleChangeFor('_replyTo')}
            value={form._replyTo}
          />
        </div>
        <ErrorMessage message={errorsMap._replyTo}/>
        <div className="form-group Contact__FormControlGroup mt-4">
          <input
            name="company"
            className="form-control Contact__FormControl"
            placeholder="Company"
            aria-label="Your company name"
            onChange={this.handleChangeFor('company')}
            value={form.company}
          />
          <ErrorMessage message={errorsMap.company}/>
        </div>
        <div className="form-group Contact__FormControlGroup mt-4 mb-4">
          <textarea
            className="form-control Contact__FormControl Contact__FormControlTextarea"
            name="message"
            placeholder="Message"
            aria-label="Your message"
            rows={5}
            onChange={this.handleChangeFor('message')}
            value={form.message}
          />
          <ErrorMessage message={errorsMap.message}/>
        </div>
        <button
          type="submit"
          className="Contact__FormControlButton d-block text-uppercase font-weight-bold"
          onClick={submitButtonHandler}
        >
          Send
        </button>
      </FormComponent>
    )
  }

  render() {
    const {
      content: {
        html,
        frontmatter: { title, name, subTitle },
      },
      apiKey,
      defaultCenter,
    } = this.props

    return (
      <PageSection id={name} className="Contact d-md-flex">
        <Container fluid>
          <Row noGutters>
            <Col xs={12} md={6} xl={4}>
              <div className="Contact__formContainer u-full-height">
                <h2 className="Contact__title mb-1">{title}</h2>
                <h3 className="Contact__subTitle mb-4">{subTitle}</h3>
                <div
                  className="Contact__body"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                {this.renderForm()}
              </div>
            </Col>
            <Col md={6} xl={8}>
              <AddressMap {...{ apiKey, defaultCenter }} />
            </Col>
          </Row>
        </Container>
      </PageSection>
    )
  }
}

export default Contact
