import * as React from 'react'

type DnaMenuContext = {
  activeKey: string
  updateActiveKey?: Function
}

const context: DnaMenuContext = {
  activeKey: null,
  updateActiveKey: (): any => undefined,
}

export default React.createContext(context)
